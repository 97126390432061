import office from "../../images/office.svg";
import shadow_1 from "../../images/Ellipse 4.svg";
import shadow_2 from "../../images/Ellipse 3.svg";
import shadow_3 from "../../images/Ellipse 2.svg";
import gaming from "../../images/gaming.svg";
import design from "../../images/graphic_design.svg";
export const productsData = [
   {
      img: gaming,
      shadow: shadow_1,
      title: "gaming",
   },
   {
      img: design,
      shadow: shadow_2,
      title: "design",
   },
   {
      img: office,
      shadow: shadow_3,
      title: "office & other",
   },
];
